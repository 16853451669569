var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useState, useEffect } from "react";
import React from "react";
import Cookies from "js-cookie";
import sortArray from "sort-array";
var BonsCoupsContext = createContext();
export var BonsCoupsProvider = function (_a) {
    var _b;
    var children = _a.children;
    var langCode = ((_b = document.getElementById("language-code")) === null || _b === void 0 ? void 0 : _b.value) || "en";
    var _c = __read(React.useState(0), 2), randQuery = _c[0], setRandQuery = _c[1];
    var _d = __read(React.useState(""), 2), searchInput = _d[0], setSearchInput = _d[1];
    var _e = __read(useState(""), 2), searchedTerm = _e[0], setSearchedTerm = _e[1];
    var _f = __read(useState([]), 2), bonsCoups = _f[0], setBonsCoups = _f[1];
    var _g = __read(useState(false), 2), bonsCoupsIsLoading = _g[0], setBonsCoupsIsLoading = _g[1];
    var _h = __read(useState({}), 2), rowsSearchData = _h[0], setRowsSearchData = _h[1];
    var _j = __read(useState(false), 2), advSearch = _j[0], setAdvSearch = _j[1];
    var _k = __read(useState(false), 2), filtersIsLoading = _k[0], setFiltersIsLoading = _k[1];
    var _l = __read(useState([]), 2), categories = _l[0], setCategories = _l[1];
    var _m = __read(useState([]), 2), selectedCategories = _m[0], setSelectedCategories = _m[1];
    var _o = __read(React.useState(new Date().getFullYear()), 2), yearInput = _o[0], setYearInput = _o[1];
    var _p = __read(React.useState([]), 2), months = _p[0], setMonths = _p[1];
    var _q = __read(useState([
        { field: "status", condition: "where", value: "approved" }
    ]), 2), rows = _q[0], setRows = _q[1];
    var _r = __read(React.useState(0), 2), count = _r[0], setCount = _r[1];
    var _s = __read(React.useState(0), 2), page = _s[0], setPage = _s[1];
    var _t = __read(React.useState(1), 2), pageCount = _t[0], setPageCount = _t[1];
    useEffect(function () {
        sortArray(rows, {
            by: ["order"],
        });
        var fetchField = function () { return __awaiter(void 0, void 0, void 0, function () {
            var response, data, fCategories, modifiedCategories, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 3, , 4]);
                        return [4 /*yield*/, fetch("/".concat(langCode, "/api/search/fields/?table=Company&context=filters&no_fetching"))];
                    case 1:
                        response = _a.sent();
                        return [4 /*yield*/, response.json()];
                    case 2:
                        data = _a.sent();
                        fCategories = data.filter(function (obj) { return obj.field_name === "type_company"; });
                        if (fCategories.length > 0 && fCategories[0].choices_labeled) {
                            modifiedCategories = fCategories[0].choices_labeled.map(function (_a) {
                                var _b = __read(_a, 2), key = _b[0], value = _b[1];
                                var translations = {
                                    fr: { "Fournisseur": "Fournisseurs", "Donneur d’ordres": "Donneurs d’ordres", "Intervenant": "Intervenants" },
                                    en: { "Supplier": "Suppliers", "Contractor": "Contractors", "Intervener": "Stakeholders" }
                                };
                                return [key, translations[langCode][value] || value];
                            });
                            setCategories(modifiedCategories);
                        }
                        else {
                            console.error("fCategories or choices_labeled is undefined or empty");
                        }
                        return [3 /*break*/, 4];
                    case 3:
                        error_1 = _a.sent();
                        console.error("Error fetching fields:", error_1);
                        return [3 /*break*/, 4];
                    case 4: return [2 /*return*/];
                }
            });
        }); };
        fetchField();
    }, [langCode]);
    useEffect(function () {
        var updatedRows = rows.map(function (row, index) {
            // First filter always uses "where"
            if (index === 0) {
                return __assign(__assign({}, row), { condition: "where" });
            }
            // Handle type_company filters with "or" between them
            if (row.field === "entreprise.type_company") {
                var previousRow = rows[index - 1];
                return __assign(__assign({}, row), { condition: previousRow && previousRow.field === "entreprise.type_company" ? "or" : "and" });
            }
            // Handle date_creation_annee filters with "or" between them
            if (row.field === "date_creation_annee") {
                var previousRow = rows[index - 1];
                return __assign(__assign({}, row), { condition: previousRow && previousRow.field === "date_creation_annee" ? "or" : "and" });
            }
            // Handle date_creation_mois filters with "or" between them
            if (row.field === "date_creation_mois") {
                var previousRow = rows[index - 1];
                return __assign(__assign({}, row), { condition: previousRow && previousRow.field === "date_creation_mois" ? "or" : "and" });
            }
            // Use "and" for all other filters
            return __assign(__assign({}, row), { condition: "and" });
        });
        var searchData = {
            query: searchInput !== searchedTerm ? searchInput : searchedTerm,
            page: page + 1,
            filters: [{ BonCoup: { conditions: updatedRows } }],
        };
        setRowsSearchData({ conditions: updatedRows });
        if (!advSearch) {
            fetchBonsCoups(searchData);
        }
    }, [page, searchedTerm, randQuery, advSearch, selectedCategories, yearInput, months]);
    var fetchBonsCoups = function (data) { return __awaiter(void 0, void 0, void 0, function () {
        var CSRF_TOKEN, response, result, updatedResults, error_2;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, 4, 5]);
                    setBonsCoupsIsLoading(true);
                    CSRF_TOKEN = Cookies.get("csrftoken");
                    return [4 /*yield*/, fetch("/".concat(langCode, "/api/search/"), {
                            method: "POST",
                            headers: {
                                "X-CSRFToken": CSRF_TOKEN,
                                "Content-Type": "application/json"
                            },
                            body: JSON.stringify(data)
                        })];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, response.json()];
                case 2:
                    result = _a.sent();
                    setPageCount(Math.ceil(result.count / 10));
                    if (Array.isArray(result.results)) {
                        updatedResults = result.results.map(function (data) {
                            var _a;
                            var companyTypeLabel = (_a = categories.find(function (_a) {
                                var _b = __read(_a, 1), key = _b[0];
                                return key === data.result.entreprise.type_company;
                            })) === null || _a === void 0 ? void 0 : _a[1];
                            return __assign(__assign({}, data.result), { entreprise: __assign(__assign({}, data.result.entreprise), { type_company_label: companyTypeLabel }) });
                        });
                        setBonsCoups(updatedResults);
                        setCount(result.count);
                    }
                    else {
                        setBonsCoups([]);
                    }
                    return [3 /*break*/, 5];
                case 3:
                    error_2 = _a.sent();
                    console.error("Error fetching BonsCoups:", error_2);
                    return [3 /*break*/, 5];
                case 4:
                    setBonsCoupsIsLoading(false);
                    return [7 /*endfinally*/];
                case 5: return [2 /*return*/];
            }
        });
    }); };
    // handle search input
    var handleSearchInput = function (e, tab) {
        if (tab === void 0) { tab = 7; }
        if (tab !== 7)
            setAdvSearch(true);
        setSearchInput(e.target.value);
    };
    var searchBonsCoups = function () {
        setPage(0);
        setSearchedTerm(searchInput);
    };
    var handleKeyDown = function (event) {
        if (event.key === "Enter") {
            setPage(0);
            setSearchedTerm(searchInput);
            searchBonsCoups();
        }
    };
    // handle category change
    var handleCategoryChange = function (selectedItems) {
        setPage(0);
        setSelectedCategories(selectedItems);
        var newCategoryFilters = selectedItems.map(function (item) { return ({
            order: 99999999,
            condition: "or",
            field: "entreprise.type_company",
            operator: "is",
            value: item.value || ""
        }); });
        setRows(function (prevRows) {
            var filteredRows = prevRows.filter(function (row) { return row.field !== "entreprise.type_company"; });
            return __spreadArray(__spreadArray([], __read(filteredRows), false), __read(newCategoryFilters), false);
        });
    };
    //handle year change
    var handleYear = function (e) {
        setPage(0);
        var newValue = e.target.value;
        var newYearFilter = {
            order: 99999999,
            condition: "and",
            field: "date_creation_annee",
            operator: "is",
            value: newValue,
        };
        setYearInput(newValue);
        setRows(function (prevRows) {
            var updatedRows = prevRows.filter(function (row) { return row.field !== "date_creation_annee"; });
            return newValue ? __spreadArray(__spreadArray([], __read(updatedRows), false), [newYearFilter], false) : updatedRows;
        });
    };
    //handle month change
    var handleMonthChange = function (months) {
        setPage(0);
        setMonths(months);
        var monthValues = months.map(function (month) { return month.value; });
        var newMonthFilters = monthValues.map(function (monthValue) { return ({
            order: 99999999,
            condition: "or",
            field: "date_creation_mois",
            operator: "is",
            value: monthValue,
        }); });
        setRows(function (prevRows) {
            var updatedRows = prevRows.filter(function (row) { return row.field !== "date_creation_mois"; });
            if (yearInput) {
                return __spreadArray(__spreadArray([], __read(updatedRows), false), __read(newMonthFilters), false);
            }
            else {
                return updatedRows;
            }
        });
    };
    var resetFilters = function () {
        setRows([]);
        setSelectedCategories([]);
        setMonths([]);
        setYearInput(new Date().getFullYear());
    };
    var handlePageClick = function (e) {
        setPage(e.selected);
        window.scrollTo(0, 0);
    };
    return (_jsx(BonsCoupsContext.Provider, __assign({ value: {
            bonsCoups: bonsCoups,
            bonsCoupsIsLoading: bonsCoupsIsLoading,
            searchBonsCoups: searchBonsCoups,
            setSearchInput: setSearchInput,
            handleSearchInput: handleSearchInput,
            setSearchedTerm: setSearchedTerm,
            resetFilters: resetFilters,
            handleKeyDown: handleKeyDown,
            handlePageClick: handlePageClick,
            handleCategoryChange: handleCategoryChange,
            handleMonthChange: handleMonthChange,
            handleYear: handleYear,
            setMonths: setMonths,
            yearInput: yearInput,
            months: months,
            pageCount: pageCount,
            page: page,
            searchInput: searchInput,
            count: count,
            rows: rows,
            rowsSearchData: rowsSearchData,
            setAdvSearch: setAdvSearch,
            setRandQuery: setRandQuery,
            filtersIsLoading: filtersIsLoading,
            categories: categories,
            setCategories: setCategories,
            selectedCategories: selectedCategories,
            setSelectedCategories: setSelectedCategories,
            setFiltersIsLoading: setFiltersIsLoading,
        } }, { children: children })));
};
export default BonsCoupsContext;
